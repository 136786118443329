<!--
 * @Author       : your name
 * @Date         : 2020-11-22 11:19:41
 * @LastEditTime : 2020-11-30 23:20:37
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/home/Partner.vue
-->
<template>
  <section :class="[isMobile ? 'mobile-partner' : '', 'partner']">
    <div :style="{backgroundImage: 'url('+info.content+')'}" v-if="isMobile" class="partner-pic"></div>
    <img v-else
      :src="info.content"
      alt=""
      class="partner-img"
      :style="partnerStyle"
      @mouseenter="enter"
      @mouseleave="leave"
    />
    <div
      class="mates"
      @mouseenter="enter"
      @mouseleave="leave"
      @click="goDetail"
    >
      <div>
        <p class="title">合作机构</p>
        <p v-if="isMobile" class="description">OUR PARTNERS</p>
        <p v-else class="description">OUR PARTNERS</p>
        <p class="more">更多 <img src="@/assets/img/common/common_add_white.png" alt="" /></p>
      </div>
    </div>
    <img src="@/assets/img/home/bottom-cirl.png" alt="" class="circle" />
  </section>
</template>

<script>
import { isMobile } from '@/helper/utils'
export default {
  props: {
    info: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.onscroll = function () {
        if (!isMobile()) {
          return false
        }
        // var documentClientHeight = document.documentElement.clientHeight || window.innerHeight
        var htmlElementClientTop = document.querySelector('.mobile-partner').offsetTop
        if (!htmlElementClientTop) {return false}
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        if (htmlElementClientTop - scrollTop < 110) {
          document.querySelector('.mobile-partner .partner-pic').style.transition = 'all .5s'
          document.querySelector('.mobile-partner .partner-pic').style.transform = 'scale(1)'
        } else {
          document.querySelector('.mobile-partner .partner-pic').style.transition = 'all .5s'
          document.querySelector('.mobile-partner .partner-pic').style.transform = 'scale(1.5)'
        }
      }
    })
  },
  data () {
    return {
      isMobile: isMobile(),
      partnerStyle: {
        transform: 'scale(1.5)'
      }
    }
  },
  methods: {
    goDetail () {
      this.$router.push('/mechanism')
    },
    enter () {
      this.partnerStyle = {
        transform: 'scale(1)'
      }
    },
    leave () {
      this.partnerStyle = {
        transform: 'scale(1.5)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-partner.partner {
  font-size: 50px;
  padding-top: .5em;
  display: flex;
  flex-direction: column;
  margin-top: -2.18rem;
  padding-top: 0;
  .partner-pic {
    width: 100vw;
    height: 12em;
    background-size: 200% auto;
    background-repeat: repeat;
    background-position: center center;
  }
  .mates {
    top: 4.8em!important;
    width: 4.33em;
    height: 4.29em;
    .title {
      font-size: .30em;
    }
    .description {
      font-size: .12em;
      transform: scale(.7);
      margin-top: .1em;
    }
    .more {
      img {
        margin-left: .05rem;
      }
    }
  }
}
.partner {
  margin-top: -2.68rem;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
  .partner-img {
    transition: all 0.6s;
    &:hover {
      transform: scale(1);
    }
  }
  .mates {
    background-image: url('../../assets/img/home/mates.png');
    width: 4.33rem;
    height: 4.29rem;
    background-size: 100% 100%;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 3rem;
    z-index: 20;
    cursor: pointer;
    .title {
      font-size: 0.3rem;
      line-height: 0.4rem;
      margin-bottom: 0;
    }
    .description {
      font-size: 0.14rem;
      margin-bottom: 0;
    }
    .more {
      font-size: 0.18rem;
      margin: 0.2rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: .15rem;
        vertical-align: middle;
        margin-left: .1rem;
      }
    }
  }
  .circle {
    position: relative;
    margin-top: -1.7rem;
  }
}
</style>

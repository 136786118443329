<!--
 * @Author       : your name
 * @Date         : 2020-11-20 16:47:59
 * @LastEditTime : 2020-12-02 18:40:46
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/views/home/home.vue
-->
<template>
  <div :class="[isMobile ? 'mobile-container' : '']">
    <section v-show="display">
      <img :src="modalImg" alt="" style="display:none;" />
      <common-header :show="display" />
      <section class="homepage">
        <banner
          :bannerOptionData="bannerOptionData"
          :bannerDescInfo="bannerDescInfo"
          :bannerList="bannerList"
          :showSwiper="display"
          @on-search-click="onSearchClick"
        />
        <div>
          <about :info="aboutInfo" />
          <social :info="socialInfo" :list="socialList" />
        </div>
        
        <home-map />
        <partner :info="partnerInfo" />
        <service />
        <transition name="fadeIn" enter-active-class="fadeInDown">
          <!-- <div class="modal" v-if="urgent && !closeUrgentFlag"> -->
          <div class="modal urgent-modal" v-if="showUrgentModalNum"
            :class="[showUrgentModalNum === 1 ? (this.isMobile ? 'oneInMobile': 'oneInPc') : '']">
            <div class="detail" :style="{top: oneUrgent.top, right: oneUrgent.right}" :id="'urgent' + uIndex"
              :class="{'hide': !oneUrgent.isShow}"
              v-for="(oneUrgent, uIndex) in urgentList" :key="uIndex">
              <div v-if="oneUrgent.isShow" class="detail-con">
                <div class="title">{{oneUrgent.title}}</div>
                <div class="content">
                  <pre>{{ oneUrgent.content }}</pre>                
                </div>
                <!-- <div class="button" @click="closeUgrent">关闭</div> -->
                <div
                  style="bottom: 0.4rem"
                  @click="closeUgrent(oneUrgent.id)"
                  class="close">
                    <img src="@/assets/img/common/common_add_white.png" alt="">
                  </div>
              </div>
            </div>
          </div>
        </transition>
        <div
          class="modal"
          v-if="showSearchModal"
        >
          <div class="detail">
            <div class="title">经纪人查询</div>
            <div class="content">
              <form>
                <div class="item">
                  <span style="font-size: .36rem; color: #ed4014;display: inline-block; height: auto;line-height: normal; padding-top: .1rem;margin-right: .05rem;">*</span>
                  <img
                    src="@/assets/img/home/name.png"
                    class="item-img"
                  /><label for="">从业人员姓名：</label
                  ><input type="text" v-model="searchInfo.name" />
                </div>
                <div class="item">
                  <span style="font-size: .36rem; color: #ed4014;display: inline-block; height: auto;line-height: normal; padding-top: .1rem;margin-right: .05rem;">*</span>
                  <img src="@/assets/img/home/id.png" class="item-img" /><label
                    for=""
                    >身份证后四位：</label
                  ><input type="text" v-model="searchInfo.id_card_no" />
                </div>
                <div class="item">
                  <span style="font-size: .36rem; color: #ed4014;display: inline-block; height: auto;line-height: normal; padding-top: .1rem;margin-right: .05rem;">*</span>
                  <img
                    src="@/assets/img/home/card.png"
                    class="item-img"
                  /><label for="">从业人员工号：</label
                  ><input type="text" v-model="searchInfo.code" />
                </div>
                <div v-if="isShowModalTips" class="item tips">
                  <span style="opacity: 0;font-size: .36rem; color: #ed4014;display: inline-block; height: auto;line-height: normal; padding-top: .1rem;margin-right: .05rem;">*</span>
                  <img style="opacity: 0;"
                    src="@/assets/img/home/card.png"
                    class="item-img"
                  /><label style="opacity: 0;" for="">从业人员工号：</label
                  >
                  请填写完整信息
                </div>
                
              </form>
            </div>
            <div class="button" @click="searchSeller">
              <img
                src="@/assets/img/home/search-m.png"
                alt=""
                class="search"
              />查询
            </div>
            <div
            @click="
                () => {
                  showSearchModal = false
                }
              "
            class="close">
              <img src="@/assets/img/common/common_add_white.png" alt="">
            </div>
            <!-- <img
              src="@/assets/img/home/close.png"
              alt="234"
              class="close"
              @click="
                () => {
                  showSearchModal = false
                }
              "
            /> -->
          </div>
        </div>
        <div class="modal" v-if="resultShow">
          <div class="detail">
            <div class="title">查询结果</div>
            <div class="content" v-if="sellerName">
              <form>
                <div
                  class="result-item"
                  v-for="(item, index) of results"
                  :key="index"
                >
                  <img
                    src="@/assets/img/home/event-select.png"
                    class="item-img"
                  />
                  <span>{{ typeName[item.type] }}：{{ item.value }}</span>
                </div>
                <p class="result-desc">
                  {{ sellerName }}是明亚保险经纪在职员工，感谢您的关注！
                </p>
              </form>
            </div>
            <div class="content" v-if="!sellerName">
              <div class="result-item">
                <p class="result-desc">
                  您查询的经纪人不存在
                </p>
              </div>
            </div>
            <div class="button" @click="closeResult">
              返回
            </div>
          </div>
        </div>
      </section>
      <common-footer />
    </section>
    <loading v-show="!display" />
  </div>
</template>

<script>
import CommonHeader from '@/components/common/Header.vue'
import CommonFooter from '@/components/common/Footer.vue'
import Loading from '@/components/common/Loading.vue'

import HomeMap from '@/components/home/Map.vue'
import Banner from '@/components/home/Banner.vue'
import About from '@/components/home/About.vue'
import Social from '@/components/home/Social.vue'
import Partner from '@/components/home/Partner.vue'
import Service from '@/components/common/Service.vue'

import { getHomepageData, getHomepageBanner, getSellerInfo } from '@/api/home'
// getSocialList

import { getNewsAndDutyData } from '@/api/aboutUs'

import modalImg from '../../assets/img/home/modal-bg.png'
import { isMobile } from '@/helper/utils'
export default {
  name: 'Home',
  data () {
    return {
      modalImg,
      results: [
        {
          type: 'name',
          value: ''
        },
        {
          type: 'number',
          value: ''
        },
        {
          type: 'phone',
          value: ''
        },
        {
          type: 'email',
          value: ''
        },
        {
          type: 'id',
          value: ''
        },
        {
          type: 'status',
          value: ''
        }
      ],
      typeName: {
        name: '姓名',
        number: '工号',
        phone: '手机号码',
        email: '邮箱',
        id: '职业证书编号',
        status: '职业证书状态'
      },
      list: [],
      urgent: '',
      // urgentTitle: '',
      urgentList: [], // 弹窗集合
      bannerList: [],
      bannerDescInfo: {},
      bannerOptionData: {},
      aboutInfo: {},
      socialInfo: {},
      partnerInfo: {},
      display: false,
      bannerLoadIndex: 0,
      showSearchModal: false,
      searchInfo: {
        code: '',
        name: '',
        id_card_no: ''
      },
      resultShow: false,
      sellerName: '',
      socialList: [],
      isShowModalTips: false,
      isMobile: isMobile(),
      showUrgentModalNum: 0, // 展示的弹窗的数量
    }
  },
  components: {
    CommonHeader,
    CommonFooter,
    HomeMap,
    Banner,
    About,
    Social,
    Partner,
    Service,
    Loading
  },
  computed: {
    closeUrgentFlag () {
      return sessionStorage.getItem('closeUrgent')
    },
  },
  beforeUpdate () {
    var docEI = document.documentElement
    if (docEI && this.isMobile) {
      docEI.style.fontSize = '71px'
    }    
  },
  watch: {
    searchInfo: {
      handler(val) {
        if (val.code && val.id_card_no && val.name) {
          this.isShowModalTips = false
        }
      },
      deep: true
    },
    urgentList: {
      handler(val) {
        const shoUrgentList = val.filter(ug => ug.isShow);
        this.showUrgentModalNum = shoUrgentList.length;
      },
      deep: true
    }
  },
  methods: {
    closeResult () {
      this.resultShow = false
    },
    async searchSeller () {
      if (!this.searchInfo.code || !this.searchInfo.name || !this.searchInfo.id_card_no) {
        this.isShowModalTips = true
        return false
      }
      const {
        name,
        code,
        mobile,
        email,
        professional_no,
        professional_no_state
      } = await getSellerInfo(this.searchInfo)
      this.sellerName = name
      this.results.forEach(item => {
        switch (item.type) {
          case 'name':
            item.value = name
            break
          case 'number':
            item.value = code
            break
          case 'phone':
            item.value = mobile
            break
          case 'email':
            item.value = email
            break
          case 'id':
            item.value = professional_no
            break
          case 'status':
            item.value = professional_no_state === 'Y' ? '有效' : '无效'
        }
      })

      this.showSearchModal = false
      this.resultShow = true
    },
    onSearchClick () {
      this.showSearchModal = true
    },
    // 弹窗逐个关闭
    closeUgrent (id) {
      // sessionStorage.setItem('closeUrgent', true)
      // this.urgent = ''
      const curUrgent = this.urgentList.find(u => u.id == id)
      this.$set(curUrgent, 'isShow', false)
      const urgentShow = {};
      this.urgentList.forEach((g) => {
        urgentShow[g.id] = g.isShow
      })
      sessionStorage.setItem('urgentShow', JSON.stringify(urgentShow))
    },
    judgeNeedGray () {
      document.getElementById('app').classList.add('need-gray')
    },
    async getData () {
      const homeRes = await getHomepageData()
      const { theme, list, urgent_messages } = homeRes
      if (theme == 2) {
        this.judgeNeedGray()
      }
      this.list = list
      this.bannerDescInfo = list.filter(item => item.id === 1)[0]
      this.bannerOptionData = list.filter(item => item.id === 29)[0]
      this.aboutInfo = list.filter(item => item.id === 2)[0]
      this.socialInfo = list.filter(item => item.id === 3)[0]
      this.partnerInfo = list.filter(item => item.id === 4)[0]
      // 多弹窗集合
      this.urgentList.length = 0
      this.urgentList.push(...urgent_messages);
      const tempObj = sessionStorage.getItem('urgentShow') ? JSON.parse(sessionStorage.getItem('urgentShow')) : {};
      if (Object.keys(tempObj).length) {
        this.urgentList.forEach((ug) => {
          const isSHow = tempObj[ug.id] === false ? false : true;
          this.$set(ug, 'isShow', isSHow)
        })
        const urgentShow = {};
        this.urgentList.forEach((g) => {
          urgentShow[g.id] = g.isShow
        })
        sessionStorage.setItem('urgentShow', JSON.stringify(urgentShow))
      } else {
        this.urgentList.forEach((ug) => {
          this.$set(ug, 'isShow', true)
        })
      }
      this.$nextTick(() => {
        const desicesW = document.documentElement.clientWidth;
        const oneUrgentW = document.querySelector('#urgent0') && document.querySelector('#urgent0').clientWidth || (this.isMobile ? 300 : 500);
        if (this.isMobile) {
          this.urgentList.forEach( (u,i) => {
            this.$set(u, 'top', (100 + i * 10) + 'px')
            this.$set(u, 'right', ((desicesW / 10) + (i - 1) * 10) + 'px')
          })
        } else {
          this.urgentList.forEach( (u,i) => {
            this.$set(u, 'top', (200 + i * 10 - this.urgentList.length * 10) + 'px')
            this.$set(u, 'right', (oneUrgentW + i * 40) + 'px')
          })
        }
      }, 500)
    },
    async getBanner () {
      let params = { booth: 100 }
      if (this.isMobile) {
        params.booth = 200
      }
      const { list } = await getHomepageBanner(params)
      list.forEach(banner => {
        banner.jsonObj = JSON.parse(banner.json)
        const img = new Image()
        const imgBg = new Image()

        img.onload = () => {
          this.bannerLoadIndex++
          if (this.bannerLoadIndex === list.length * 2) {
            this.display = true
          }
        }
        img.src = banner.pic

        imgBg.onload = () => {
          this.bannerLoadIndex++
          if (this.bannerLoadIndex === list.length * 2) {
            this.display = true
          }
        }
        imgBg.src = banner.bg_pic
      })
      this.bannerList = list
      // console.log(list)
    },
    async getSocialList () {
      const { records } = await getNewsAndDutyData({
        page: 1,
        limit: 3,
        type: 2,
        position: 1
      })
      this.socialList = records.slice(0, 3)
    }
  },
  async mounted () {
    this.getData()
    this.getBanner()
    this.getSocialList()
  }
}
</script>

<style lang="scss" scoped>
.mobile-container {
  width: 100vw;
  overflow-x: hidden;
}
.mobile-modal.modal {
  .detail {
    padding-bottom: 1rem;
    width: 80%;
    height: auto;
    .close {
      position: static;
      // position: relative;
      left: 0;
      transform: translateX(0);
      top: 0.5rem;
      background-color: #fe6917;
      img {
        width: 50%;
        transform: rotate(45deg);
      }
    }
    .button {
      position: static;
      width: 4rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 15%;
        height: auto;
      }
    }
    .item {
      margin-bottom: 0;
      .item-img {
        width: 0.4rem;
        height: 0.4rem;
      }
      label {
        font-size: 0.36em;
      }
      input {
        height: 1.2rem;
        line-height: 1.2rem;
        flex: 1;
      }
    }
    .title {
      height: 2rem;
      line-height: 2rem;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }
}
.modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .result-item {
    font-size: 0.18rem;
    margin-bottom: 0.2rem;
    padding-left: 0.3rem;
    img {
      width: 0.14rem;
      height: 0.14rem;
      margin-right: 0.12rem;
    }
  }
  .result-desc {
    font-size: 0.2rem;
    color: #ffa025;
    padding-left: 0.3rem;
  }
  .close {
    width: 0.82rem;
    height: 0.82rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.2rem;
    margin: auto;
    cursor: pointer;
    background-color: #fe6917;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40%;
      transform: rotate(45deg);
    }
  }
  .detail {
    position: relative;
    box-sizing: border-box;
    width: 7.4rem;
    height: 6.7rem;
    background-image: url('../../assets/img/home/modal-bg.png');
    background-size: 100% 100%;
    padding: 0 0.2rem;
    position: relative;
    color: #333;
    .title {
      height: 0.98rem;
      line-height: 0.98rem;
      border-bottom: 1px solid #e3e3e8;
      font-size: 0.3rem;
      text-align: center;
    }
    .content {
      padding: 0.6rem 0.44rem;
      font-size: 0.18rem;
      line-height: 0.36rem;
    }
    .button {
      width: 2rem;
      height: 0.6rem;
      line-height: 0.6rem;
      position: absolute;
      bottom: 0.6rem;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      color: #fff;
      background: linear-gradient(180deg, #fe6917, #ff8a21);
      border-radius: 0.08rem;
      cursor: pointer;
      font-size: 0.2rem;

      img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.1rem;
        vertical-align: middle;
      }
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.3rem;
      &.tips {
        // justify-content: center;
        // padding-left: 1.96rem;
        margin-top: -.3rem;
        color: rgb(237, 64, 20);
      }
      img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.1rem;
      }

      label {
        font-size: 0.18rem;
        display: inline-block;
        margin-right: 0.2rem;
      }

      input {
        width: 3.8rem;
        height: 0.6rem;
        line-height: 0.6rem;
        padding-left: 0.1rem;
      }
    }
  }
}
.red {
  color: $base-font-color;
}
.home-modal {
  width: 5rem;
  height: 5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  z-index: 999;
}

pre {
  margin-bottom: 0;
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  text-align: justify;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
// 多弹窗调整
.urgent-modal {
  .detail {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 5.4rem;
    height: 5.7rem;
    padding-right: 0;
    .detail-con {
      height: 100%;
    }
    .content {
      height: 3.6rem;
      padding: 0.3rem 0.44rem 0;
      overflow-y: auto;
    }
    .close {
      width: 0.5rem;
      height: 0.5rem;
    }
    
    &.hide {
      display: none;
    }
  }
  &.oneInMobile .detail {
    right: 50% !important;
    top: 50% !important;
    margin-right: -40%;
    margin-top: -50%;
  }
  &.oneInPc .detail {
    right: 50% !important;
    top: 50% !important;
    margin-right: -2.7rem;
    margin-top: -2.85rem;
  }
}
//我只改了这里
@media only screen and (max-width: $moble_width) {
  .modal .detail {
    width: 80%;
    height: auto;
    max-height: 80%;
    padding-bottom:1rem;
    .item{
      margin-bottom: .1rem;
      &.tips {
        margin-bottom: 0;
        margin-top: .2rem;
        label, img, span {
          display: none!important;
        }
      }
        flex-wrap: wrap;
        .item-img{
          width: 12px!important;
          height: 12px!important;
        }
        label{
          font-size: 12px!important;
        }
        input{
          margin-top: 5px;
          width: 100%;
          flex-shrink: 0!important;
          flex-basis: auto!important;
          height: 30px!important;
          line-height: 30px!important;
          font-size: 14px;
        }
    }
    .close{
      position: static;
      left: 50%;
      // transform: translateX(-50%);
      top: .5rem;
      width: 20px;
      height: 20px;
      margin-top: .5rem;
    }
    .title {
      height: 45px;
      line-height: 45px;
      font-size: 16px;
    }
    .content {
      padding: 20px 15px;
      font-size: 14px;
      line-height: 1.5;
      .result-item,
      .result-desc {
        font-size: 14px;
      }
    }
    .button {
      position: relative;
      width: 100px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      bottom:auto;
      img{
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>

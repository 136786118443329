<!--
 * @Author       : your name
 * @Date         : 2020-11-21 11:31:58
 * @LastEditTime : 2020-12-02 19:22:34
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/home/About.vue
-->
<template>
  <section :class="[isMobile ? 'mobile-about' : '', 'about']">
    <div v-if="!isMobile" class="left-hr"></div>
    <div v-if="!isMobile" class="bettwen-hr"></div>
    <div class="mountain-bg">
      <div
        class="dot"
        :class="[`dot${index}`, currentIndex === index ? 'active' : '']"
        :key="index"
        v-for="(dot, index) of dots"
        @click="switchSlide(index)"
      >
        <span>
          {{ dot }}
        </span>
      </div>
      <template v-if="isMobile">
        <div class="event-detail" v-if="showUp && details.length > 0">
          <swiper :options="swiperOptions" ref="mySwiper">
            <swiper-slide
              v-for="(banner, index) of details"
              :key="index"
              @click.native="goToDetail(index)"
            >
              <div class="event-change">
                <!-- :src="banner.pic" -->
                <div
                  alt=""
                  class="title-img"
                  :style="`backgroundImage:url(${banner.home_pic})`"
                />
                <div class="right-content">
                  <p class="title">{{ banner.name }}</p>
                  <p class="desc">
                    {{ banner.discribe }}
                  </p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div v-if="!isMobile" class="control">
            <img
              :src="currentIndex > 0 ? loImg : lgImg"
              alt=""
              class="left"
              @click="
                () => {
                  if (currentIndex > 0) {
                    swiper.slidePrev()
                  }
                }
              "
            />
            <img
              :src="currentIndex < details.length - 1 ? roImg : rgImg"
              class="right"
              @click="
                () => {
                  if (currentIndex < details.length - 1) {
                    swiper.slideNext()
                  }
                }
              "
            />
          </div>
        </div>
      </template>
      <template v-else>
        <transition name="fadeIn" enter-active-class="fadeInUp">
          <div class="event-detail" v-if="showUp && details.length > 0">
            <swiper :options="swiperOptions" ref="mySwiper">
              <swiper-slide
                v-for="(banner, index) of details"
                :key="index"
                @click.native="goToDetail(index)"
              >
                <div class="event-change">
                  <!-- :src="banner.pic" -->
                  <div
                    alt=""
                    class="title-img"
                    :style="`backgroundImage:url(${banner.home_pic})`"
                  />
                  <div class="right-content">
                    <p class="title">{{ banner.name }}</p>
                    <p class="desc">
                      {{ banner.discribe }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div v-if="!isMobile" class="control">
              <img
                :src="currentIndex > 0 ? loImg : lgImg"
                alt=""
                class="left"
                @click="
                  () => {
                    if (currentIndex > 0) {
                      swiper.slidePrev()
                    }
                  }
                "
              />
              <img
                :src="currentIndex < details.length - 1 ? roImg : rgImg"
                class="right"
                @click="
                  () => {
                    if (currentIndex < details.length - 1) {
                      swiper.slideNext()
                    }
                  }
                "
              />
            </div>
          </div>
        </transition>
      </template>
    </div>
    <div class="about-detail">
      <lazy-component @show="aboutShow">
        <div class="about-show-flag"></div>
      </lazy-component>
      <transition name="slideIn" enter-active-class="slideInLeft">
        <div v-if="showLeft" class="tr">
          <div class="about-title">
            <span>关于我们</span>
            <template v-if="!isMobile">
              <span class="about-divider">/</span>
              <span class="about-about">about</span>
              <span class="about-us">us</span>
            </template>
          </div>
          <div class="hr"></div>
          <div class="mingya">MINGYA</div>
          <div class="desc">
            <pre :class="[isSmallScreen ? 'small-screen' : '']">{{ info.content }}</pre>
          </div>
        </div>
      </transition>

      <div class="plus" @click="goMore">
        <span>更多</span>
        <!-- <img src="@/assets/img/home/p-n.png" alt="" /> -->
        <img src="@/assets/img/common/common_add_white.png" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import rgImg from '@/assets/img/home/r-g.png'
import roImg from '@/assets/img/home/r-o.png'
import lgImg from '@/assets/img/home/l-g.png'
import loImg from '@/assets/img/home/l-o.png'

import { getHomepageEvents } from '@/api/home'
import { isMobile, isSmallScreen } from '@/helper/utils'
// import { getNewsAndDutyData } from '@/api/aboutUs'

export default {
  props: {
    info: {
      type: Object,
      default () {
        return {
          showChange: false
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    dots () {
      let arr = this.details.map(
        item => item.disploy_at && item.disploy_at.slice(0, 4)
      )
      arr.push('更多')
      return arr
    }
  },
  data () {
    const that = this
    return {
      isMobile: isMobile(),
      isSmallScreen: isSmallScreen(),
      currentIndex: 0,
      times: [2020, 2019, 2018, 2017, 2016, '更多'],
      rgImg,
      roImg,
      lgImg,
      loImg,
      details: [],
      showLeft: false,
      showUp: false,
      swiperOptions: {
        autoplay: true,
        loop: true,
        effect: 'fade',
        // 变手势
        grabCursor: true,
        speed: 2000,
        initialSlide: 0,
        // 滑动方向，水平horizontal，垂直vertical
        // direction: 'horizontal',
        on: {
          init: function () {
            // setTimeout(() => {
            //   swiperAni.swiperAnimateCache(this)
            //   swiperAni.swiperAnimate(this)
            // }, 300)
          },
          slideChange: function () {
            // console.log('that.swiper.realIndex----->', that.swiper.realIndex)
            that.currentIndex = that.swiper.realIndex
          }
        }
      }
    }
  },
  methods: {
    goToDetail (index) {
      const { id } = this.details[index]
      this.$router.push(`aboutUs/detail?type=news&id=${id}`)
    },
    goMore () {
      this.$router.push('aboutUs/Introduction')
    },
    switchSlide (index) {
      if (index == 5) {
        this.$router.push('aboutUs/news')
        return
      }
      this.currentIndex = index

      this.swiper.slideTo(index + 1, 1000)
    },
    aboutShow () {
      if (!this.showLeft) {
        this.showLeft = true
      }
      if (!this.showUp) {
        setTimeout(() => {
          this.showUp = true
          this.currentIndex = 0
        }, 1000)
      }
    },
    async getEvents () {
      const res = await getHomepageEvents()
      this.details = res.slice(0, 5)
    }
  },
  created () {
    this.getEvents()
  }
}
</script>

<style lang="scss" scoped>
.mobile-about.about {
  font-size: 50px;
  margin-bottom: 30px;
  min-height: auto;
  padding-top: 0;
  .about-detail {
    .hr {
      width: .5em;
      height: .03em;
      margin-top: .15em;
    }
    .tr {
      height: auto;
    }
    box-sizing: border-box;
    padding: 0 .4em;
    width: 100%;
    height: auto;
    position: static;
    margin-top: 1em;
    margin-left: 0;
    .plus {
      width: 80px;
      height: 30px;
      position: static;
      border-radius: 15px;
      margin-top: 2em;
      span {
        font-size: 12px;
      }
      img {
        width: 9px;
        height: 9px;
        margin-left: 5px;
      }
    }
    .mingya {
      font-size: 1em;
      text-align: right;
      letter-spacing: .3em;
      margin-top: 0;
      margin-left: 0;
      margin-right: -.3em;
    }
    .desc {
      width: 100%;
      font-size: .24em;
      line-height: 1.8em;
    }
    .about-title {
      width: auto;
      font-size: .36em;
      font-weight: bold;
      .about-divider {
        margin-left: 1.5em;
        font-size: .18em;
        font-weight: normal;
        letter-spacing: 1.5em;
        
      }
      .about-about {
          font-weight: normal;
          letter-spacing: 1.5em;
        }
    }
  }
  .mountain-bg {
    position: static;
    width: 100%;
    height: 5.93em;
    background-size: 110% 100%;
    background-position: right center;
    position: relative;
    .dot {
      width: .5em;
      height: .5em;
      &.active::before {
        top: 50%;
        left: 50%;
        margin-left: -.3rem;
        margin-top: -.3rem;
      }
      span {
        font-size: 12px;
        left: 0;
        top: 30px;
        white-space: nowrap;
      }
      &.dot0 {
        left: .2em;
        top: 3em;
      }
      &.dot1 {
        left: 1.1em;
        top: 3.95em;
      }
      &.dot2 {
        left: 2.2em;
        top: 4.7em;
      }
       &.dot3 {
        left: 3.6em;
        top: 5.3em;
      }
      &.dot4 {
        left: 5.1em;
        top: 5.65em;
      }
      &.dot5 {
        left: 6.5em;
        top: 5.65em;
      }
    }
    
    .event-detail {
      width: 7em;
      height: 2.3em;
      position: absolute;
      left: 50%;
      top: .6em;
      transform: translateX(-50%);
      background-size: 100% 100%;
      .swiper-slide .event-change {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 .20em .1em;
        box-sizing: border-box;
        .title-img {
          margin: 0;
          width: 1.8em;
          height: 1.8em;
        }
        .right-content {
          box-sizing: border-box;
          padding: 0 .6em 0 .2em;
          overflow: hidden;
          margin-top: 0;
          width: auto;
          p {
            margin-bottom: 0;
          }
          flex: 1;
          .title {
            font-weight: bold;
            font-size: .3em;
             display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          }
          .desc {
            width: 100%;
            font-size: 12px;
            display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
            text-align: justify;
          }
        }
      }
    }
  }
}
pre {
  margin-bottom: 0;
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  text-align: justify;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  line-height: 1.5em;
  &.small-screen {
    font-size: 14px;
    line-height: 28px;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
    background-image: url('../../assets/img/home/event-unselected.png');
    background-size: 100% 100%;
  }
  25% {
    transform: scale(1);
    opacity: 1;
    background-image: url('../../assets/img/home/event-select.png');
    background-size: 100% 100%;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.swiper-slide {
  cursor: auto;
}
.left-hr {
  width: 1.9rem;
  // width: 0;
  height: 1px;
  background-color: #dcdcdc;
  position: absolute;
  top: 3.8rem;
  left: 0;
}
.bettwen-hr {
  width: 1px;
  height: 1.37rem;
  background-color: #dcdcdc;
  position: absolute;
  top: 8.4rem;
  left: 2.78rem;
  opacity: 0;
}
// .swiper-container-fade .swiper-slide {
//   display: flex;
// }
.swiper-slide .event-change {
  width: 6rem;
  z-index: 999999;
  display: flex;
  opacity: 0;
  cursor: pointer;
}

.swiper-slide.swiper-slide-active .event-change {
  opacity: 1;
}

.opacity0 {
  opacity: 0;
}
.tr {
  transition: all 2s;
  height: 6rem;
}
.about-show-flag {
  width: 10px;
  height: 10px;
  // border: 1px solid red;
  position: absolute;
  bottom: 3rem;
}
.about {
  // background-color: #f5f7f9;
  // background-color: #fff;
  background-image: url(../../assets/img/home/about_bg.png);  
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  // background-image: linear-gradient(to right,#fdfdfd, #f5f7f9);
  position: relative;
  min-height: 9.68rem;
  position: relative;
  padding-top: 1px;
  // margin-bottom: 1rem;
  .mountain-bg {
    width: 11.45rem;
    height: 9.68rem;
    background-image: url('../../assets/img/home/mountain.png');
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
    .dot {
      width: 0.6rem;
      height: 0.6rem;
      background-image: url('../../assets/img/home/event-unselected.png');
      background-size: 100% 100%;
      color: $normal-gray;
      position: absolute;
      cursor: pointer;
      transition: all 1s;
      span {
        position: absolute;
        bottom: -0.4rem;
        left: 0.14rem;
        width: 100%;
      }
      &.active {
        background-image: url('../../assets/img/home/event-select.png');
        &::before {
          content: '';
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          border: 1px solid rgba(254, 94, 23, 0.5);
          box-shadow: 0 0 5px (254, 94, 23, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          animation: pulse 2s linear infinite;
        }
      }
    }
    .dot0 {
      top: 6.1rem;
      left: 2.2rem;
    }
    .dot1 {
      top: 7.45rem;
      left: 3.6rem;
    }
    .dot2 {
      bottom: 0.7rem;
      left: 5.24rem;
    }
    .dot3 {
      bottom: 0.1rem;
      right: 4.06rem;
    }
    .dot4 {
      bottom: -0.3rem;
      right: 2.4rem;
    }
    .dot5 {
      bottom: -0.25rem;
      right: 0.9rem;
      background-image: url('../../assets/img/common/common_add_orange.png');
      background-size: 45% 45%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff;
      background-repeat: none;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
    }
    .event-detail {
      width: 8.6rem;
      height: 3.15rem;
      background-size: cover;
      background-image: url('../../assets/img/home/event-bg.png');
      position: absolute;
      top: 2.2rem;
      right: 1.5rem;
      display: flex;
      .title-img {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        margin: 0.3rem;
        border: 2px solid #e6e6e6;
        background-size: cover;
      }
      .right-content {
        width: 2.96rem;
        margin-top: 0.6rem;
        .title {
          font-size: 0.2rem;
          margin-bottom: 0.3rem;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .desc {
          font-size: 0.14rem;
          width: 2.96rem;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          text-align: justify;
        }
      }
      .control {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0.4rem;
        margin-top: 1.45rem;
        padding-right: 0.8rem;
        img {
          width: 0.1rem;
          height: 0.15rem;
          cursor: pointer;
        }
      }
    }
  }
  .about-detail {
    margin-top: 3.4rem;
    margin-left: 2.3rem;
    position: relative;
    width: 8rem;
    height: 7.8rem;
    z-index: 10;
    .about-title {
      font-size: 0.28rem;
      font-family: 'SourceHanSansCN-Regular', 'Microsoft YaHei', Arial,
        sans-serif;
      font-weight: 400;
      line-height: 0.74rem;
      // width: 3rem;
    }
    .about-divider {
      display: inline-block;
      margin: 0 0.2rem;
      color: $orange;
    }
    .about-about {
      text-transform: uppercase;
      font-size: 0.16rem;
      color: $orange;
      letter-spacing: 0.1rem;
    }
    .about-us {
      text-transform: uppercase;
      font-size: 0.16rem;
      color: #dcdcdc;
      letter-spacing: 0.1rem;
      display: inline-block;
      margin-left: .1rem;
      // position: absolute;
      // top: 0rem;
      // right: -1rem;
    }
    .hr {
      height: 0.04rem;
      background: $orange;
      width: 0.4rem;
      margin: 0.1rem 0;
    }
    .mingya {
      font-size: 1.1rem;
      color: $light-gray;
      margin-left: 0.66rem;
      letter-spacing: 0.2rem;
      font-weight: bold;
      margin-top: -0.1rem;
    }
    .desc {
      font-size: 0.16rem;
      color: $normal-gray;
      width: 6.1rem;
      line-height: 0.4rem;
    }
  }
  .plus {
    width: 1.6rem;
    height: 0.6rem;
    border-radius: 0.3rem;
    background-color: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: #fff;
    left: 0;
    bottom: 0;
    font-size: 0.18rem;

    cursor: pointer;
    transition: all 0.8s;
    &:hover {
      transform: scale(1.2);
    }
    img {
      width: 0.16rem;
      height: 0.16rem;
      margin-left: 0.08rem;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[_vm.isMobile ? 'mobile-about' : '', 'about']},[(!_vm.isMobile)?_c('div',{staticClass:"left-hr"}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"bettwen-hr"}):_vm._e(),_c('div',{staticClass:"mountain-bg"},[_vm._l((_vm.dots),function(dot,index){return _c('div',{key:index,staticClass:"dot",class:[("dot" + index), _vm.currentIndex === index ? 'active' : ''],on:{"click":function($event){return _vm.switchSlide(index)}}},[_c('span',[_vm._v(" "+_vm._s(dot)+" ")])])}),(_vm.isMobile)?[(_vm.showUp && _vm.details.length > 0)?_c('div',{staticClass:"event-detail"},[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.details),function(banner,index){return _c('swiper-slide',{key:index,nativeOn:{"click":function($event){return _vm.goToDetail(index)}}},[_c('div',{staticClass:"event-change"},[_c('div',{staticClass:"title-img",style:(("backgroundImage:url(" + (banner.home_pic) + ")")),attrs:{"alt":""}}),_c('div',{staticClass:"right-content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(banner.name))]),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(banner.discribe)+" ")])])])])}),1),(!_vm.isMobile)?_c('div',{staticClass:"control"},[_c('img',{staticClass:"left",attrs:{"src":_vm.currentIndex > 0 ? _vm.loImg : _vm.lgImg,"alt":""},on:{"click":function () {
                if (_vm.currentIndex > 0) {
                  _vm.swiper.slidePrev()
                }
              }}}),_c('img',{staticClass:"right",attrs:{"src":_vm.currentIndex < _vm.details.length - 1 ? _vm.roImg : _vm.rgImg},on:{"click":function () {
                if (_vm.currentIndex < _vm.details.length - 1) {
                  _vm.swiper.slideNext()
                }
              }}})]):_vm._e()],1):_vm._e()]:[_c('transition',{attrs:{"name":"fadeIn","enter-active-class":"fadeInUp"}},[(_vm.showUp && _vm.details.length > 0)?_c('div',{staticClass:"event-detail"},[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.details),function(banner,index){return _c('swiper-slide',{key:index,nativeOn:{"click":function($event){return _vm.goToDetail(index)}}},[_c('div',{staticClass:"event-change"},[_c('div',{staticClass:"title-img",style:(("backgroundImage:url(" + (banner.home_pic) + ")")),attrs:{"alt":""}}),_c('div',{staticClass:"right-content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(banner.name))]),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(banner.discribe)+" ")])])])])}),1),(!_vm.isMobile)?_c('div',{staticClass:"control"},[_c('img',{staticClass:"left",attrs:{"src":_vm.currentIndex > 0 ? _vm.loImg : _vm.lgImg,"alt":""},on:{"click":function () {
                  if (_vm.currentIndex > 0) {
                    _vm.swiper.slidePrev()
                  }
                }}}),_c('img',{staticClass:"right",attrs:{"src":_vm.currentIndex < _vm.details.length - 1 ? _vm.roImg : _vm.rgImg},on:{"click":function () {
                  if (_vm.currentIndex < _vm.details.length - 1) {
                    _vm.swiper.slideNext()
                  }
                }}})]):_vm._e()],1):_vm._e()])]],2),_c('div',{staticClass:"about-detail"},[_c('lazy-component',{on:{"show":_vm.aboutShow}},[_c('div',{staticClass:"about-show-flag"})]),_c('transition',{attrs:{"name":"slideIn","enter-active-class":"slideInLeft"}},[(_vm.showLeft)?_c('div',{staticClass:"tr"},[_c('div',{staticClass:"about-title"},[_c('span',[_vm._v("关于我们")]),(!_vm.isMobile)?[_c('span',{staticClass:"about-divider"},[_vm._v("/")]),_c('span',{staticClass:"about-about"},[_vm._v("about")]),_c('span',{staticClass:"about-us"},[_vm._v("us")])]:_vm._e()],2),_c('div',{staticClass:"hr"}),_c('div',{staticClass:"mingya"},[_vm._v("MINGYA")]),_c('div',{staticClass:"desc"},[_c('pre',{class:[_vm.isSmallScreen ? 'small-screen' : '']},[_vm._v(_vm._s(_vm.info.content))])])]):_vm._e()]),_c('div',{staticClass:"plus",on:{"click":_vm.goMore}},[_c('span',[_vm._v("更多")]),_c('img',{attrs:{"src":require("@/assets/img/common/common_add_white.png"),"alt":""}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<!--
 * @Author       : your name
 * @Date         : 2020-11-21 17:06:49
 * @LastEditTime : 2020-11-30 23:12:47
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/home/Social.vue
-->
<template>
  <div class="social-container">
    <section v-if="isMobile" class="mobile-social">
      <div class="top">
        <div class="mobile-social-title">
          <div class="cn">社会责任</div>
        </div>
        <div class="mobile-social-detail">
          <pre>{{ info.content }}</pre>
        </div>
      </div>
      <div class="swiper-containers">
        <div class="content-box">
          <div class="swiper-content">
            <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(item, index) in list" :key="item.id">
                <div @click="goToDetail(index)" class="title">{{item.name}}</div>
                <div @click="goToDetail(index)" class="desc">{{item.discribe}}</div>
              </swiper-slide>
            </swiper>            
          </div>
          <div class="more">
            <div class="plus" @click="goMore">
              <span>更多</span>
              <!-- <img src="@/assets/img/home/p-n.png" alt="" /> -->
              <img src="@/assets/img/common/common_add_white.png" alt="" />
            </div>
            <div class="page-handles">
              <img v-if="currentSwiperIndex == 0" class="prev" src="../../assets/img/aboutus/prev.png" alt="">
              <img @click="swiperHandle('prev')" v-else class="prev" src="../../assets/img/aboutus/prev_active.png" alt="">
              <img v-if="currentSwiperIndex == list.length - 1" class="next" src="../../assets/img/aboutus/next.png" alt="">
              <img @click="swiperHandle('next')" v-else class="next" src="../../assets/img/aboutus/next_active.png" alt="">
            </div>
          </div>
        </div>        
      </div>
    </section>
    <section v-else class="social">
    <lazy-component @show="socialShow">
      <div class="social-show-flag"></div>
    </lazy-component>
    <transition name="fadeIn" enter-active-class="fadeInLeft">
      <div class="social-left" v-if="showSocial"></div>
    </transition>
    <div class="social-right">
      <div class="content">
        <template>
          <p class="t1">SOCIAL/</p>
          <p class="t2">RESPONSIBILITY</p>
        </template>
        <div class="title">社会责任</div>
        <div class="divider"></div>
        <p class="detail">
          <pre :class="[isSmallScreen ? 'small-screen' : '']">{{ info.content }}</pre>
        </p>
      </div>

      <img src="@/assets/img/home/social-r-bg.png" alt="" class="main-img" />
      <div class="options">
        <div
          class="item"
          :class="currentIndex === index ? 'active' : ''"
          v-for="(item, index) of list"
          :key="index"
          @mouseenter="
            () => {
              currentIndex = index
            }
          "
          @click="goToDetail(index)"
        >
          <p class="title">{{ item.name }}</p>
          <p class="desc">
            {{ item.discribe }}
          </p>
          <img
          style="transform: scale(.75)" 
            :src="currentIndex === index ? pwImg : pgImg"
            alt=""
            class="plus"
          />
        </div>
        <div class="item more" @click="goMore">
          <div class="more-container">
            <span>更多</span>
            <!-- <img src="@/assets/img/home/more-event.png" alt="" class="arrow" /> -->
            <img src="@/assets/img/common/common_add_orange.png" alt="" class="arrow" />
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  
</template>

<script>
import pwImg from '@/assets/img/common/common_add_white.png'
import pgImg from '@/assets/img/common/common_add_gray.png'
import { isMobile, isSmallScreen } from '@/helper/utils'
export default {
  props: {
    info: {
      type: Object,
      default () {
        return {}
      }
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    let _this = this
    return {
      isSmallScreen: isSmallScreen(),
      isMobile: isMobile(),
      currentSwiperIndex: 0,
      swiperOptions: {
        autoplay: true,
        // loop: true,
        on: {
            slideChange(){
              _this.currentSwiperIndex = _this.$refs.mySwiper.swiper.realIndex
            },
        }
      },
      showSocial: false,
      pwImg,
      pgImg,
      currentIndex: 0,
      socials: [
        {
          title: '中国儿童保险专项基金',
          desc:
            '借助保险参与慈善事业，是明亚的一大创举。这一创举源于 2007 年 11 月 18 日，明亚成...'
        },
        {
          title: '自闭症青年入职明亚 ',
          desc:
            '星艺联盟，是一个由残障儿童康复中心）联合明亚等十余家爱心企业组成的..'
        },
        {
          title: '孤儿圆梦红包 ',
          desc:
            '2018 年 9 月，由明亚参与发起的中华儿慈会 “孤儿圆梦红包”（全国性）项目正式启动...'
        }
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    // 轮播控制
    swiperHandle (type) {
      console.log(this.swiper)
      if (type === 'prev') {
        this.swiper.slidePrev()
      } else {
        this.swiper.slideNext()
      }
    },
    goMore () {
      this.$router.push('aboutUs/responsibility')
    },
    socialShow () {
      setTimeout(() => {
        this.showSocial = true
      }, 1000)
    },
    goToDetail (index) {
      const { id } = this.list[index]
      this.$router.push({
        path: '/aboutUs/detail?type=responsibility&id=' + id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  margin-bottom: 0;
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  text-align: justify;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  line-height: 1.5em;
  &.small-screen {
    font-size: 14px;
    line-height: 28px;
  }
}
.mobile-social {
  font-size: 50px;
  .swiper-containers {
    box-sizing: border-box;
    // padding-top: .48em;
    padding-left: .4em;
    .content-box {      
      box-shadow: 0px 6px 19px 0px #EFF1F5;
      box-sizing: border-box;
      padding-left: .4em;
      padding-top: .48em;
      .more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: .4em;
        margin-top: 20px;
        padding-bottom: 20px!important;
        .plus {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          // font-size: 12px;
          width: 80px;
          height: 30px;
          line-height: 30px;
          border-radius: 15px;
          background-color: #fe6917;
          color: #ffffff;
          text-align: center;
          span {
            color: #ffffff;
            font-size: 12px;
            display: block;
          }
          img {
            width: 9px;
            height: 9px;
            margin-left: 5px;
            vertical-align: middle;
          }
        }
        span {
          font-size: .8em;
        }
        display: flex;
        padding-bottom: .2em;
        .page-handles {
          display: flex;
          margin-left: .1em;
          .prev {
            margin-right: .4em;
          }
          img {
            width: .13em;
            height: .16em;
          }
        }
      }
      span {
        color: #CCCCCC;
        font-size: .8em;
      }
    }
    .swiper-content {
      display: flex;
      align-items: flex-start;
      padding-right: .4em;
      .more {
        
      }
      
      .swiper {
        flex: 1;
      }
      .page-handles {
        display: flex;
        margin-left: .1em;
        img {
          width: .13em;
          height: .16em;
        }
      }
    }
    .title {
      font-size: .3em;
      font-weight: bold;
      display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
    }
    .desc {
      margin-top: .8em;
      font-size: .24em;
      color: #808080;
      line-height: 1.8em;
      height: 3.6em;
      display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
    }
  }
  .top {
    background-image: url(../../assets/img/home/mobile-social-bg.png);
    background-size: 100% auto;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-bottom: 4em;
    padding-top: .48em;
  }
  .mobile-social-detail {
    font-size: .24em;
    color: #7D7D7D;
    line-height: 1.8em;
    box-sizing: border-box;
    padding: 0 2em;
    text-align: justify;
    margin-top: 2em;
  }
  .mobile-social-title {
    text-align: right;
    box-sizing: border-box;
    padding: 0 .4em;
    .en {
      font-size: .18em;
      letter-spacing: 1.5em;
      color: #DCDCDC;
      margin-right: -1em;
      &.color {
        color: #FE6917;
        margin-bottom: .2em;
      }
    }
    .cn {
      font-size: .36em;
      padding: .3em 0;
      position: relative;
      margin-top: .3em;
      font-weight: bold;
      &::after {
        content: "";
        display: block;
        width: 1.5em;
        height: .1em;
        background-color: #FE6917;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
.social {
  // margin-top: -.4rem;
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-image: url(../../assets/img/home/social.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  .more-container {
    cursor: pointer;
    transition: all 0.8s;
    &:hover {
      transform: scale(1.2);
    }
  }
  .social-show-flag {
    height: 0.5rem;
    width: 0.3rem;
    // border: 1px solid red;
    position: absolute;
    bottom: 3rem;
    z-index: 999;
  }
  .social-left {
    background-image: url('../../assets/img/home/social-l-bg.png');
    width: 9.6rem;
    height: 9.6rem;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    bottom: 0.92rem;
  }
  .social-right {
    width: 12.9rem;
    position: relative;
    z-index: 9;

    .content {
      text-align: right;
      position: absolute;
      right: 2.4rem;
      top: 2.1rem;
      p {
        margin-bottom: 0.1rem;
      }
      .t1 {
        font-size: 0.16rem;
        color: $orange;
        letter-spacing: 0.2rem;
        position: relative;
        &::after {
          display: block;
          content: "";
          width: 1.9rem;
          height: 1px;
          background-color: #dcdcdc;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(130%);
          right: 0;
        }
      }
      .t2 {
        // color: $light-gray;
        color: #dcdcdc;
        letter-spacing: 0.2rem;
        margin-right: 0.54rem;
      }
      .title {
        color: #333;
        font-size: 0.3rem;
      }
      .divider {
        height: 0.04rem;
        background: #fe6917;
        width: 0.4rem;
        margin: 0.1rem 0;
        float: right;
      }
      .detail {
        color: $normal-gray;
        font-size: 0.16rem;
        width: 7.68rem;
        margin-right: 3rem;
        margin-top: -0.6rem;
        line-height: 0.4rem;
        text-align: left;
      }
    }

    .main-img {
      width: 100%;
    }
    .options {
      height: 3.4rem;
      width: 100%;
      display: flex;
      background-color: #fff;
      .item {
        padding: 0.56rem 0.52rem;
        width: 25%;
        position: relative;
        transition: background-image 1s;
        background-image: url('../../assets/img/home/w.png');
        cursor: pointer;
        &.active {
          background-image: url('../../assets/img/home/social-o-bg.png');
          background-size: 100% 100%;
          transition: all 0.8s;
          * {
            color: #fff !important;
          }
        }
        .title {
          color: #2b2b2b;
          font-size: 0.2rem;
          margin-bottom: 0.3rem;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .desc {
          color: #808080;
          font-size: 0.16rem;
          line-height: 0.3rem;
          margin-bottom: 0.66rem;
          width: 2.6rem;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .plus {
          width: 0.26rem;
          height: 0.26rem;
          cursor: pointer;
          position: absolute;
          bottom: 0.62rem;
          left: 0.542rem;
        }
        .arrow {
          width: 0.16rem;
          height: 0.16rem;
          margin-left: 0.08rem;
          cursor: pointer;
        }
      }
      .more {
        color: #fe6917;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        font-weight: bold;
      }
    }
  }
}
</style>

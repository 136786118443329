/*
 * @Author       : your name
 * @Date         : 2020-11-25 15:48:34
 * @LastEditTime : 2020-12-02 18:32:54
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/api/home.js
 */
import request from '@/helper/request'

export function getHomepageData () {
  return request({
    url: 'api/index',
    method: 'get',
    data: {
      code: 'index'
    }
  })
}

export function getHomepageBanner (params) {
  return request({
    url: 'api/banners',
    method: 'get',
    params: params
  })
}

export function getHomepageEvents () {
  return request({
    url: 'api/news-and-duty-last-five-year?limit=5',
    method: 'get'
  })
}

export function getHomepageOrgans (payload) {
  return request({
    url: 'api/organs?position=1&limit=200',
    method: 'get',
    params: payload
  })
}

export function getSellerInfo (payload) {
  return request({
    url: 'api/saler',
    method: 'get',
    params: payload
  })
}

export function getSocialList (payload) {
  return request({
    url: 'api/news-and-duty-list',
    method: 'post',
    params: payload
  })
}

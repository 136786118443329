<!--
 * @Author       : your name
 * @Date         : 2020-11-21 11:13:17
 * @LastEditTime : 2020-12-02 18:42:09
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/home/Banner.vue
-->
<template>
  <section
    :class="[isMobile ? 'mobile-banner' : '', 'banner']"
    :style="bannerStyle"
    v-if="bannerList.length > 0 && showSwiper"
  >
    <swiper :options="swiperOptions" ref="mySwiper">
      <!-- slides -->
      <swiper-slide v-for="(banner, index) of bannerList" :key="index">
        <a :href="banner.redirect_target || '#'">
          <img :src="banner.pic" alt="" class="banner-main" />
          <div
            class="desc ani"
            swiper-animate-effect="slideInRight"
            swiper-animate-duration="2s"
            :style="banner.jsonObj.boxStyle"
            v-if="banner && banner.jsonObj && banner.jsonObj.texts"
          >
            <p
              v-for="(text, textIndex) of banner.jsonObj.texts"
              :key="textIndex"
              :style="text.style"
            >
              {{ text.value }}
            </p>
          </div>
        </a>
      </swiper-slide>
    </swiper>
    <div class="banner-desc-container">
      <div
        class="desc-con"
        :style="{ backgroundImage: `url(${bannerList[currentIndex].bg_pic})` }"
        @click="searchClick"
      >
        <div>
          ---&nbsp; &nbsp;&nbsp; &nbsp;<img
          class="search-icon"
            src="@/assets/img/home/search-bannner.png"
            alt=""
          />
          &nbsp;&nbsp;&nbsp; &nbsp; ---
        </div>
        <p>经纪人搜索</p>
      </div>
      <div v-if="isMobile" class="banner-desc" :style="{backgroundImage: 'url('+bannerOptionData.content+')'}"></div>
      <!-- <img  :src="bannerDescInfo.content" alt="" class="banner-desc" /> -->
      <img v-else :src="bannerDescInfo.content" alt="" class="banner-desc" />
    </div>
  </section>
</template>

<script>
import * as swiperAni from '@/lib/swiper.animate.min.js'
import { isMobile } from '@/helper/utils'
export default {
  props: {
    bannerList: {
      type: Array,
      default () {
        return []
      }
    },
    bannerDescInfo:{
      type: Object,
      default () {
        return {}
      }
    },
    bannerOptionData:{
      type: Object,
      default () {
        return {}
      }
    },
    showSwiper: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const that = this
    return {
      isMobile: isMobile(),
      slide: 0,
      sliding: null,
      currentIndex: 0,
      swiperOptions: {
        autoplay: { delay: 5000 },
        loop: false,
        effect: 'fade',
        // 变手势
        grabCursor: true,
        initialSlide: 0,
        // 滑动方向，水平horizontal，垂直vertical
        // direction: 'horizontal',
        on: {
          init: function () {
            // setTimeout(() => {
            //   swiperAni.swiperAnimateCache(this)
            //   swiperAni.swiperAnimate(this)
            // }, 300)
          },
          slideChange: function () {
            swiperAni.swiperAnimate(this)
            that.currentIndex = that.swiper.realIndex
          }
        }
      },
      bannerStyle: {
        transform: 'scale(1.2)'
      }
    }
  },
  watch: {
    showSwiper (val) {
      if (val) {
        setTimeout(() => {
          this.bannerStyle = {
            transform: 'scale(1)'
          }
        }, 800)
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    },
    searchClick () {
      this.$emit('on-search-click')
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-banner {
  width: 100vw;
  font-size: 50px;
  min-height: auto!important;
  .swiper-container {
    a {
      display: flex;
      justify-content: center;
    }
    .banner-main {
      width: 100vw;
      height: 98vw;
      // height: 90vw;
      // margin: 0 auto;
    }
  }
  .banner-desc-container {
    position: static;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    width: 100%;
    height: 1.41em;
    .banner-desc {
      width: 74%;
      height: 100%;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-repeat: no-repeat;
      background-position: center;
    }
    .desc-con {
      height: 100%;
      width: 26%;
      background-size: 150% 150%;
      background-position: center center;
      padding-top: 0;
      .search-icon {
        width: 21.5%;
        height: auto;
      }
      p {
        padding-top: 0;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}
.banner-desc-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12.8rem;
  height: 1.6rem;
  display: flex;
  z-index: 999;
  .desc-con {
    width: 3.2rem;
    height: 100%;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding-top: 1rem;
    font-size: 0.18rem;
    transition: all 0.8s;
    cursor: pointer;
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    img {
      width: 0.55rem;
      height: 0.52rem;
      margin-bottom: 0.1rem;
      transition: all 0.6s;
      margin-left: 0.16rem;
      margin-right: 0.16rem;
    }
  }
  .banner-desc {
    width: 9.6rem;
    height: 100%;
  }
}
.banner {
  transition: all 2s;
  min-height: 8.8rem;
  position: relative;
}
.desc {
  position: absolute;
}
.swiper-container {
  .banner-main {
    width: 100%;
  }
}
.homepage .carousel-indicators {
  left: 2.38rem;
  bottom: 8.5%;
  right: auto !important;
  top: auto !important;
  li {
    width: 0.16rem !important;
    height: 0.16rem !important;
    border-radius: 50% !important;
    margin-right: 0.24rem;
    outline: none;
  }
}
</style>
